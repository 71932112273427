import {
  DocumentArrowUpIcon,
  DocumentIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import Upload from '../../../../../components/Upload/Upload';
import {
  postAnswerFileFormQuestion,
  postRemoveContentFileAnswer,
} from '../../../../../services/forms/formContentService';

export default function FileAnswer({ content, session }) {
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(
    content?.answer?.file && {
      name: content?.answer?.file?.split('/')?.at(-1),
    },
  );

  const [fileUrl, setFileUrl] = useState(content?.answer?.file);

  return (
    <div className='flex flex-col gap-4'>
      {!file ? (
        <>
          <button
            className='flex h-fit w-fit flex-row items-center gap-2 rounded
                          border border-slate-300 p-2 text-sm font-semibold text-primary'
            onClick={() => {
              setOpen(true);
            }}
          >
            <DocumentArrowUpIcon className='h-5' />
            Add file {/* to translate */}
          </button>
          {'Maximum file size: 4MB'}
        </>
      ) : (
        <div
          className='flex w-fit flex-row items-center rounded border
                          border-slate-300 p-2 text-center'
        >
          <a
            className='flex flex-row items-center gap-2 text-primary hover:underline'
            href={fileUrl}
            target='_blank'
            rel='noreferrer'
            title={file?.name}
          >
            <DocumentIcon className='h-5 text-red-400' />
            <p className='max-w-[9rem] overflow-hidden truncate text-start'>
              {file?.name}
            </p>
          </a>
          <XMarkIcon
            className='ml-2 h-5 cursor-pointer'
            onClick={() => {
              setFile();
              postRemoveContentFileAnswer(content.uuid);
            }}
          />
        </div>
      )}

      <CustomDialog
        isOpen={open}
        closeModal={() => setOpen(false)}
        children={
          <div>
            <Upload
              //   accept=''
              setFile={(file) => {
                setFile(file);
                console.log(file);
                const url = URL.createObjectURL(file);
                setFileUrl(url);

                const body = new FormData();
                body.append('file', file);
                body.append('session', session);
                setUploading(true);
                postAnswerFileFormQuestion(content.uuid, body)?.finally(() => {
                  setUploading(false);
                });
                setOpen(false);
              }}
            />
          </div>
        }
      />
    </div>
  );
}
