import React, { useCallback, useRef, useState } from 'react';
import {
  Bars3BottomLeftIcon,
  CloudArrowUpIcon,
  InformationCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import MultipleChoice from './Options/MultipleChoice';
import Select from '../../../../../../components/forms/Select/Select';
import ContentFooter from './ContentFooter';
import {
  patchFormContent,
  postRemoveContentImage,
} from '../../../../../../services/forms/formContentService';
import Checkboxes from './Options/Checkboxes';
import CheckboxIcon from '../../../../../../components/Icons/CheckboxIcon';
import MultipleChoiceIcon from '../../../../../../components/Icons/MultipleChoiceIcon';
import UploadImageModal from './UploadImageModal';
import SimpleLoader from '../../../../../../components/Loader/SimpleLoader';
import MiniRichTextEditor from '../../../../../../components/forms/Input/MiniRichTextEditor';
import TextAnswer from './Options/TextAnswer';
import FileAnswer from './Options/FileAnswer';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import { toast } from 'react-toastify';
import GripIcon from '../../../../../../components/Icons/GripIcon';

export default function ContentCard({
  content,
  selected,
  selectContent,
  setContents,
  update,
  setPoints,
  dragProps,
  isDragging,
  draggingId,
}) {
  const timer = useRef();
  const ref = useRef();
  const [imageUploading, setImageUploading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [title, setTitle] = useState('');

  const handleDeleteImage = () => {
    toast.dark(
      <>
        <div>You sure want to delete content "{content.name}"?</div>
        {/*to translate*/}
        <div className='mt-3 flex justify-center gap-3'>
          <Button
            text={'Yes'} // to translate
            color='error-solid'
            autoFocus
            onClick={() => {
              setImageUploading(true);
              postRemoveContentImage(content.uuid)
                ?.then(() => {
                  update()?.finally(() => {
                    setImageUploading(false);
                  });
                })
                ?.catch((err) => {
                  console.log(err);
                  setImageUploading(false);
                  toast.error(
                    `Error when deleting "${content.name}"`, // to translate
                  );
                });
            }}
          />
          <Button
            text={'No'} // to translate
            className='text-white'
            onClick={toast.dismiss}
          />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  const saveContent = useCallback(
    (value, field) => {
      patchFormContent(content.uuid, { [field]: `${value}` })?.then((res) => {
        setTitle(res.name);
      });
    },
    [content.uuid],
  );

  const debounceSaveData = useCallback(
    (value, field) => {
      // Отправить запрос через 1 секунду после последнего ввода
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        if (field === 'name' && value === '') {
          ref.current.innerHTML = title || content?.name;
          return;
        }
        saveContent(value, field);
        console.log(value);
      }, 1000);
    },
    [content?.name, saveContent, title],
  );

  return (
    <>
      <div
        id={content.uuid}
        onClick={selectContent}
        className={`group/card flex flex-col gap-2 rounded-lg border-l-[0.4rem] bg-white pb-6 pr-6 pt-0
                   ${selected ? 'border-l-blue-500' : 'border-l-white'} ${
          isDragging && 'opacity-50'
        }`}
      >
        <div className='flex flex-col items-center pt-2'>
          <div
            {...dragProps}
            className={`${
              draggingId && draggingId !== content.order?.toString()
                ? ''
                : 'group-hover/card:visible'
            } ${!(isDragging || selected) && 'invisible'} text-secondary`}
          >
            <GripIcon className='absolute top-2 h-4 rotate-90' />
          </div>
        </div>
        <div className='flex flex-row items-start pl-6'>
          <MiniRichTextEditor
            ref={ref}
            borderColor='text-violet-500'
            className={`${selected && 'border-b'} w-full focus:outline-none`}
            defaultValue={content.name}
            onChange={(e) => {
              debounceSaveData(e, 'name');
            }}
          />
          <div className='flex flex-row items-center gap-2'>
            {selected && (
              <>
                {imageUploading ? (
                  <div className='relative'>
                    <SimpleLoader className='h-5' />
                  </div>
                ) : (
                  <UploadImageModal
                    uuid={content.uuid}
                    update={update}
                    setUploading={setImageUploading}
                  />
                )}
                {contentLoading ? (
                  <div className='relative'>
                    <SimpleLoader className='h-5' />
                  </div>
                ) : (
                  <Select
                    className='w-40'
                    value={content.test_type}
                    options={[
                      {
                        label: 'Multiple choice',
                        value: 'SELECTONE',
                        Icon: MultipleChoiceIcon,
                      },
                      {
                        label: 'Checkboxes',
                        value: 'SELECT',
                        Icon: CheckboxIcon,
                      },
                      {
                        label: 'Text answer',
                        value: 'TEXT',
                        Icon: Bars3BottomLeftIcon,
                      },
                      {
                        label: 'File answer',
                        value: 'FILE',
                        Icon: CloudArrowUpIcon,
                      },
                      {
                        label: 'Content',
                        value: 'CONTENT',
                        Icon: InformationCircleIcon,
                      },
                      {
                        label: 'Dropdown',
                        value: 'DROPDOWN',
                        Icon: InformationCircleIcon,
                      },
                    ]}
                    onChange={(e) => {
                      setContentLoading(true);

                      patchFormContent(content.uuid, {
                        test_type: e?.value,
                      })
                        ?.then(() => {
                          update()?.finally(() => {
                            setContentLoading(false);
                          });
                        })
                        ?.catch(() => {
                          setContentLoading(false);
                        });
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {imageUploading && (
          <center>
            <SimpleLoader className='h-5' />
          </center>
        )}
        {content.attachment_img && !imageUploading && (
          <div className='group/image relative my-2 w-fit'>
            <img
              alt='attachment'
              className=': h-fit max-h-64 w-fit'
              src={content.attachment_img}
            />
            {selected && (
              <TrashIcon
                className='invisible absolute -top-3 -right-3 h-7 
              cursor-pointer rounded-full bg-slate-200 p-1.5 text-error group-hover/image:visible'
                onClick={() => handleDeleteImage()}
              />
            )}
          </div>
        )}
        {contentLoading ? (
          <center>
            <SimpleLoader className='h-5' />
          </center>
        ) : (
          <>
            {['SELECTONE', 'DROPDOWN']?.includes(content.test_type) && (
              <MultipleChoice
                content={content}
                selected={selected}
                update={update}
              />
            )}
            {content.test_type === 'SELECT' && (
              <Checkboxes
                content={content}
                selected={selected}
                update={update}
              />
            )}
            {content.test_type === 'TEXT' && <TextAnswer />}
            {content.test_type === 'FILE' && <FileAnswer />}
            <div
              className={`${
                selected ? 'flex' : 'hidden'
              } ml-6 flex-row border-b pt-4`}
            />

            <ContentFooter
              setPoints={setPoints}
              content={content}
              selected={selected}
              setContents={setContents}
            />
          </>
        )}
      </div>
    </>
  );
}
