const BoysIcon = () => (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_37_3266)'>
      <path
        d='M49.7705 0H10C4.47715 0 0 4.47716 0 10V49.7984C0 55.3212 4.47715 59.7984 10 59.7984H49.7705C55.2933 59.7984 59.7705 55.3212 59.7705 49.7984V10C59.7705 4.47715 55.2933 0 49.7705 0Z'
        fill='#111233'
      />
      <path
        d='M59.6717 29.8522L29.9946 0.161133L0.317383 29.8522L29.9946 59.5432L59.6717 29.8522Z'
        fill='#6C8CE4'
      />
      <path
        d='M30.1385 14.7256L35.3931 24.3357L44.9987 29.5927L35.3931 34.8497L30.1385 44.4598L24.8839 34.8497L15.2783 29.5927L24.8839 24.3357L30.1385 14.7256Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_37_3266'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const GirlsIcon = () => (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_36_3262)'>
      <path
        d='M49.6552 0H10.3218C4.85959 0 0.431641 4.47716 0.431641 10V49.7984C0.431641 55.3212 4.85959 59.7984 10.3218 59.7984H49.6552C55.1173 59.7984 59.5453 55.3212 59.5453 49.7984V10C59.5453 4.47715 55.1173 0 49.6552 0Z'
        fill='#111233'
      />
      <path
        d='M59.4482 29.8522L30.0972 0.161133L0.746094 29.8522L30.0972 59.5432L59.4482 29.8522Z'
        fill='#ED7051'
      />
      <path
        d='M30.2388 14.7256L35.4356 24.3357L44.9357 29.5927L35.4356 34.8497L30.2388 44.4598L25.042 34.8497L15.542 29.5927L25.042 24.3357L30.2388 14.7256Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_36_3262'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const MixedIcon = () => (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_37_3275)'>
      <path
        d='M49.9785 0H10.208C4.68516 0 0.208008 4.47716 0.208008 10V49.7984C0.208008 55.3212 4.68515 59.7984 10.208 59.7984H49.9785C55.5013 59.7984 59.9785 55.3212 59.9785 49.7984V10C59.9785 4.47715 55.5013 0 49.9785 0Z'
        fill='#111233'
      />
      <path
        d='M59.8798 29.8522L30.2025 0.161133L0.525391 29.8522L30.2025 59.5432L59.8798 29.8522Z'
        fill='#C9B291'
      />
      <path
        d='M30.3455 14.7256L35.6001 24.3357L45.2057 29.5927L35.6001 34.8497L30.3455 44.4598L25.091 34.8497L15.4854 29.5927L25.091 24.3357L30.3455 14.7256Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_37_3275'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const REGIONS = {
  '03': [
    {
      icon: BoysIcon,
      name: 'welcome.astana_bil',
      contacts: [
        {
          phone: '+7 7172 53 88 54',
        },
        { phone: '+7 705 131 56 02', hasWhatsapp: true },
      ],
      address: 'welcome.astana_bil_address',
    },
    {
      icon: GirlsIcon,
      name: 'welcome.astana_kyz',
      contacts: [
        {
          phone: '+7 775 707 78 96',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.astana_kyz_address',
    },
    {
      icon: BoysIcon,
      name: 'welcome.kokshetau_bil',
      contacts: [
        {
          phone: '+7 771 595 77 76',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.kokshetau_bil_address',
    },
    {
      icon: MixedIcon,
      name: 'welcome.shuchinsk_bil',
      contacts: [
        {
          phone: '+7 7163 62 11 45',
          hasWhatsapp: false,
        },
        {
          phone: '+7 771 113 40 97',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.shuchinsk_bil_address',
    },
  ],
  '04': [
    // Aqtobe
    {
      icon: BoysIcon,
      name: 'welcome.aqtobe_bil',
      contacts: [
        {
          phone: '+7 7132 46 71 77',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.aqtobe_bil_address',
    },
  ],
  '05': [
    // Almaty, Almaty oblast
    {
      icon: BoysIcon,
      name: 'welcome.almaty_bil',
      contacts: [
        {
          phone: '+7 727 230 69 43',
          hasWhatsapp: false,
        },
        {
          phone: '+7 707 327 80 99',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.almaty_bil_address',
    },
    {
      icon: BoysIcon,
      name: 'welcome.esik_bil',
      contacts: [
        {
          phone: '+7 707 498 34 56',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.esik_bil_address',
    },
  ],
  '06': [
    // Atyrau
    {
      icon: BoysIcon,
      name: 'welcome.atyrau_bil',
      contacts: [
        {
          phone: '+7 778 133 77 88',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.atyrau_bil_address',
    },
    {
      icon: GirlsIcon,
      name: 'welcome.atyrau_kyz',
      contacts: [
        {
          phone: '+7 7122 30 43 55',
          hasWhatsapp: false,
        },
        {
          phone: '+7 778 871 53 84',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.atyrau_kyz_address',
    },
  ],
  '07': [
    // ZKO
    {
      icon: BoysIcon,
      name: 'welcome.oral_bil',
      contacts: [
        {
          phone: '+7 706 430 10 46',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.oral_bil_address',
    },
  ],
  '08': [
    // Zhambyl
    {
      icon: BoysIcon,
      name: 'welcome.taraz_bil',
      contacts: [
        {
          phone: '+7 7262 45 14 15',
          hasWhatsapp: false,
        },
        {
          phone: '+7 778 171 73 79',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.taraz_bil_address',
    },
    {
      icon: GirlsIcon,
      name: 'welcome.taraz_kyz',
      contacts: [
        {
          phone: '+7 7262 57 74 66',
          hasWhatsapp: false,
        },
        {
          phone: '+7 747 102 05 84',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.taraz_kyz_address',
    },
  ],
  '09': [
    // Karaganda
    {
      icon: BoysIcon,
      name: 'welcome.karagandy_bil',
      contacts: [
        {
          phone: '+7 771 358 61 38',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.karagandy_bil_address',
    },
    {
      icon: GirlsIcon,
      name: 'welcome.karagandy_kyz',
      contacts: [
        {
          phone: '+7 7212 50 22 20',
          hasWhatsapp: false,
        },
        {
          phone: '+7 778 281 08 27',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.karagandy_kyz_address',
    },
  ],
  10: [
    // Kostanay
    {
      icon: BoysIcon,
      name: 'welcome.kostanay_bil',
      contacts: [
        {
          phone: '+7 706 600 45 46',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.kostanay_bil_address',
    },
  ],
  11: [
    // Kyzylorda
    {
      icon: BoysIcon,
      name: 'welcome.kyzylorda_bil',
      contacts: [
        {
          phone: '+7 7242 21 24 92',
          hasWhatsapp: false,
        },
        {
          phone: '+7 707 255 00 09',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.kyzylorda_bil_address',
    },
    {
      icon: GirlsIcon,
      name: 'welcome.kyzylorda_kyz',
      contacts: [
        {
          phone: '+7 7242 70 00 80',
          hasWhatsapp: false,
        },
        {
          phone: '+7 707 255 00 10',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.kyzylorda_kyz_address',
    },
  ],
  12: [
    // Mangystau
    {
      icon: BoysIcon,
      name: 'welcome.aqtau_bil',
      contacts: [
        {
          phone: '+7 7292 33 84 27',
          hasWhatsapp: false,
        },
        {
          phone: '+7 777 112 93 77',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.aqtau_bil_address',
    },
    {
      icon: BoysIcon,
      name: 'welcome.zhanaozen_bil',
      contacts: [
        {
          phone: '+7 7293 45 59 76',
          hasWhatsapp: false,
        },
        {
          phone: '+7 747 670 87 44',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.zhanaozen_bil_address',
    },
  ],
  13: [
    // Shymkent, Turkestan oblast
    {
      icon: BoysIcon,
      name: 'welcome.shymkent_bil',
      contacts: [
        {
          phone: '+7 7252 32 11 56',
          hasWhatsapp: false,
        },
        {
          phone: '+7 700 235 93 89',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.shymkent_bil_address',
    },
    {
      icon: GirlsIcon,
      name: 'welcome.shymkent_kyz',
      contacts: [
        {
          phone: '+7 7252 57 66 62',
          hasWhatsapp: false,
        },
        {
          phone: '+7 747 635 67 34',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.shymkent_kyz_address',
    },
    {
      icon: BoysIcon,
      name: 'welcome.turkestan_bil',
      contacts: [
        {
          phone: '+7 702 790 85 05',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.turkestan_bil_address',
    },
    {
      icon: GirlsIcon,
      name: 'welcome.turkestan_kyz_bil',
      contacts: [
        {
          phone: '+7 702 790 85 05',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.turkestan_kyz_bil_address',
    },
    {
      icon: BoysIcon,
      name: 'welcome.arys_bil',
      contacts: [
        {
          phone: '+7 7254 02 16 54',
          hasWhatsapp: false,
        },
        {
          phone: '+7 705 566 16 40',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.arys_bil_address',
    },
  ],
  14: [
    // Pavlodar
    {
      icon: BoysIcon,
      name: 'welcome.pavlodar_bil',
      contacts: [
        {
          phone: '+7 7182 31 03 62',
          hasWhatsapp: false,
        },
        {
          phone: '+7 700 031 03 62',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.pavlodar_bil_address',
    },
    {
      icon: GirlsIcon,
      name: 'welcome.pavlodar_kyz',
      contacts: [
        {
          phone: '+7 7182 60 71 98',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.pavlodar_kyz_address',
    },
    {
      icon: BoysIcon,
      name: 'welcome.ekibastuz_bil',
      contacts: [
        {
          phone: '+7 7187 75 02 08',
          hasWhatsapp: false,
        },
        {
          phone: '+7 777 541 56 46',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.ekibastuz_bil_address',
    },
  ],
  15: [
    // SKO
    {
      icon: MixedIcon,
      name: 'welcome.petropavl_bil',
      contacts: [
        {
          phone: '+7 7152 51 72 31',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.petropavl_bil_address',
    },
  ],
  16: [
    // VKO
    {
      icon: BoysIcon,
      name: 'welcome.oskemen_bil',
      contacts: [
        {
          phone: '+7 723 225 29 62',
          hasWhatsapp: false,
        },
        {
          phone: '+7 705 187 58 01',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.oskemen_bil_address',
    },
  ],
  18: [
    // Abay
    {
      icon: BoysIcon,
      name: 'welcome.semey_bil',
      contacts: [
        {
          phone: '+7 722 277 15 76',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.semey_bil_address',
    },
  ],
  19: [
    // Zhetisu
    {
      icon: BoysIcon,
      name: 'welcome.taldykorgan_bil',
      contacts: [
        {
          phone: '+7 7282 22 26 69',
          hasWhatsapp: false,
        },
        {
          phone: '+7 700 024 72 82',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.taldykorgan_bil_address',
    },
  ],
  20: [
    // Ulutau
    {
      icon: BoysIcon,
      name: 'welcome.zhezkazgan_bil',
      contacts: [
        {
          phone: '+7 7102 41 00 99',
          hasWhatsapp: false,
        },
        {
          phone: '+7 700 113 19 93',
          hasWhatsapp: true,
        },
      ],
      address: 'welcome.zhezkazgan_bil_address',
    },
  ],
};
